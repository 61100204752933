.content {
  padding: 0 20px;
}
.dashboard-hd {
    margin: 10px 0;
    min-height: 48px;
}

/*General styling*/

/*Widgets*/
.recharts-cartesian-axis-tick {
    font-size: .75rem;
    font-family: Lato, sans-serif;
}

/*Widget layout:*/
.widget-hd {
    padding: 15px 24px;
    font-size: 16px;
    font-family: Lato, sans-serif;
    text-align: left;
    font-weight: 700;
}

.recharts-legend-item {
    margin: 0 0 5px;
    color: #666;
}

.recharts-legend-item-text {
    margin-right: 15px;
    font-family: Lato, sans-serif;
    font-size: .85rem;
}

/*Widget types:*/

.dashboard-widget {
    font-family: Lato, sans-serif;
}

.dashboard-widget .Dashboard-editPenIcon {
    opacity: 0;
    -webkit-transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.dashboard-widget:hover .Dashboard-editPenIcon {
    opacity: 1;
}

.dashboard-widget.dashboard-widget__type-number {}
.dashboard-widget.dashboard-widget__type-number .widget-body  {
    padding: 15px 24px;
}

.dashboard-widget.dashboard-widget__type-number .widget-body .widget-value {
    font-size: 2rem;
    text-align: left;
    font-weight: 500;
}

.dashboard-widget.dashboard-widget__type-number .widget-body .widget-delta {
    padding: 15px 0;
    font-size: 1.25rem;
    text-align: left;
}

.dashboard-widget.dashboard-widget__type-number .widget-body .widget-delta._positive {
    color: #06A33B;
}

.dashboard-widget.dashboard-widget__type-number .widget-body .widget-delta._negative {
    color: #B02815;
}

/**/

.recharts-default-tooltip {
    font-family: Lato, sans-serif;
}

.tooltip {
    font-family: Lato, sans-serif;
    background: #ffffff;
    padding: 40px;
    box-sizing: border-box;
    border-left: 1px solid #fff;
    z-index: 1000;
    text-align: left;
    margin: 20px;
}
.tooltip .number{
    font-size: 40px;
    display: block;
}
.tooltip .label{
    display: block;
}

.reference-label text{
    fill: rgba(0,0,0, 0.8);
    font-family: 'Lato', sans-serif;
}

.logo-holder {
    width: 100%;
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
}

.logo-holder img {
    max-width: 200px;
    height: auto;
    margin: 0 auto;
    display: block;
}
/* Date input */
.DateInput {
    width: 135px;
    background: #1e1e1e;
}

.DateRangePickerInput {
    background: #1e1e1e;
    color: #fff;
    border-radius: 0;
}

.DateInput_input {
    background: #1e1e1e;
    color: #bebebe;
    cursor: pointer;
    font-family: Lato, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    padding: 8px 20px 5px;
}

.DateInput_input::-ms-input-placeholder,
.DateInput_input:-ms-input-placeholder,
.DateInput_input::-webkit-input-placeholder  {
    text-transform: uppercase;
}

.DateInput_input::placeholder,
.DateInput_input:-ms-input-placeholder,
.DateInput_input::-webkit-input-placeholder  {
    text-transform: uppercase;
}
.DateRangePickerInput_arrow {
    color: #bebebe;
}

.DateInput_input__focused {
    border-bottom: 2px solid #fffb4e;
    color: #fff;
}

/* General settings */
.DayPicker {
    font-family: Lato, sans-serif;
}

.DateRangePicker_picker,
.CalendarMonthGrid,
.CalendarMonth,
.DayPicker__horizontal {
    background-color: #1f1f1f;
}

.CalendarMonth_caption {
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    padding-top: 25px;
}

.DateInput_fangShape {
    fill: #1e1e1e;
}
.DayPickerKeyboardShortcuts_showSpan, 
.DayPickerKeyboardShortcuts_panel {
    color: #1f1f1f;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #fffb4e;
}
.DayPickerKeyboardShortcuts_closeSvg {
    fill: #1f1f1f;
}

/* Navigation  */
.DayPickerNavigation_button__default {
    border: none;
    background: #343334;
    color: #bdbdbd;
    border-radius: 0;
}


.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
    background: #3f3f3f;
    border: none;
}
.DayPickerNavigation_button__default:focus > .DayPickerNavigation_svg__horizontal,
.DayPickerNavigation_button__default:hover > .DayPickerNavigation_svg__horizontal {
    fill: #fff;
}

.DayPickerNavigation_button__default:active {
    background: #fffb4e;
}

.DayPickerNavigation_button__default:active > .DayPickerNavigation_svg__horizontal {
    fill: #000;
}

/* Weekheader */
.DayPicker_weekHeader_ul {
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 0;
}

/* Calendars days */
.CalendarDay__default {
    border: 1px solid #454444;
    color: #bebebe;
    background: #343434;
}

.CalendarDay__selected {
    background: #fffb4e;
    color: #000;
    border: 1px double #e1de52;
}

.CalendarDay__hovered_span {
   background: #b6b342 !important;
   color: #000;
   border: 1px double #e1de52;
}

.CalendarDay__selected_span {
    background: #b6b342;
    color: #000;
    border: 1px double #e1de52;
}


.CalendarDay__default:hover {
    background: #fffb4e;
    color: #000;
    border: 1px double #fffb4e;
}

.CalendarDay__hovered_span:hover {
    background: #b6b342 !important;
    color: #000;
    border: 1px double #e1de52;
}

.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #313116;
    color: #fff;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #1e1e1e;
    border: 1px solid #2e2d2d;
    color: #636363;
    font-family: Lato, sans-serif;
}

/* Range Picke panel */

.PresetDateRangePicker_panel {
    padding: 0 22px 20px
}
      
  
.PresetDateRangePicker_button {
    height: 100%;
    text-transform: uppercase;
    font-size: 12px;
    border: none;
    background: #353535;
    color: #bebebe;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.PresetDateRangePicker_button:hover {
    background: #3f3f3f;
    color: #fff;
    border: none;
}

.PresetDateRangePicker_button__selected,
.PresetDateRangePicker_button__selected:hover{
    background: #fffb4e;
    color: #000;
}

.cardAvatar {
    margin: 0 15px 15px 0;
}
.user-card {
    padding: 10px 15px;
    margin-bottom: 16px;
}

.user-card__container {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.user-card__part_container {
	display: flex;
	margin-right: 16px;
	width: 25%;
}

.user-card__part_container-tight {
	display: flex;
	margin-right: 16px;
}

.user-card__part_container-last {
	display: flex;
	flex-grow: 1;
}

.user-card__part_container-tight-last {
	display: flex;
}

.user-card__part_item {
	padding: 0px 16px 0 0;
}

.user-card__verified {
	position: relative;
	width: 32px;
}

.user-card__verified > svg {
	position: absolute;
}
.signInContainer {
    font-family: Lato, sans-serif;
    text-transform: uppercase;
}

.signInContainer:before{
    content:'';
    height: 100vh;
    background-image: url(/static/img/login.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: -82px;
    z-index: -1;
    width: 100%;
    position: absolute;
}

/* selector 'signInContainer' is used for apply styles */
.signInContainer .signInWrapper {
    background-color: #1f1f1f;
    color: #fff;
    border-radius: 0;
    padding: 25px;
    min-height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}

.signInContainer .signInWrapper:before{
    background-image: url(/static/img/new_r_w.svg);
    background-repeat: no-repeat;
    background-size: 170px 300px;
    background-position: -1px 100%;
    position: absolute;
    display: block;
    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    opacity: 0.2;
    bottom: 0;
}

/* selector 'signInForm' is used for apply styles */
.signInWrapper h5,
.signInWrapper p {
    color: #fff;
    font-family: Lato, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
}

.signInWrapper h5 {
    font-size: 21pxrem;
    margin-bottom: 20px;
}

.signInWrapper p {
    font-size: 0.775rem;
}
.signInWrapper p.small,
.signInWrapper p.small button {
    font-size: 0.675rem;
}

.signInWrapper h5.bold,
.signInWrapper p.bold,
.signInWrapper a.bold {
    font-weight: bold;
}

.signInWrapper a {
    text-decoration: underline;
}

.signInWrapper .signInBtn {
    color: #fff;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-size: 0.775rem;
    padding: 9px 50px;
    border-color: #646363;
    border-radius: 0;
}

.signInWrapper .signInBtn:focus,
.signInWrapper .signInBtn:hover{
    background-color: #2b2b2b;
}

.signInHeader {
    padding: 17px 90px 40px 0;
    position: relative;
}

.signInHeaderIcon {
    background-repeat: no-repeat;
    background-position: 100%;
    content: '';
    position: absolute;
    height: 112px;
    width: 112px;
    right: 0;
    top: 0;
}

#username, 
#password,
#usernamereset,
#newpassword,
#code {
    background-color: #fff;
    /* not less than 16px, for not zooming in Safari/iPone */
    font-size: 16px;
    padding: 16px 5px 15px 15px;
    font-family: Lato, sans-serif;
}

.signInInput {
    width: 100%;
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 18px;
}

.signInForm .signInInput:after {
    border-bottom: 3px solid #df32b7;
}

.signInLinks {
    flex-grow: 1;
}

.signInWrapper .BtnAsLink {
    color: #fff;
    padding: 0;
    line-height: 1.46429em;
    text-decoration: underline;
    font-weight: bold;
    border-bottom: 2px solid transparent;
}
#hubspot-messages-iframe-container.widget-align-right {
    display: none!important;
}
