.signInContainer {
    font-family: Lato, sans-serif;
    text-transform: uppercase;
}

.signInContainer:before{
    content:'';
    height: 100vh;
    background-image: url(/static/img/login.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: -82px;
    z-index: -1;
    width: 100%;
    position: absolute;
}

/* selector 'signInContainer' is used for apply styles */
.signInContainer .signInWrapper {
    background-color: #1f1f1f;
    color: #fff;
    border-radius: 0;
    padding: 25px;
    min-height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}

.signInContainer .signInWrapper:before{
    background-image: url(/static/img/new_r_w.svg);
    background-repeat: no-repeat;
    background-size: 170px 300px;
    background-position: -1px 100%;
    position: absolute;
    display: block;
    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    opacity: 0.2;
    bottom: 0;
}

/* selector 'signInForm' is used for apply styles */
.signInWrapper h5,
.signInWrapper p {
    color: #fff;
    font-family: Lato, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
}

.signInWrapper h5 {
    font-size: 21pxrem;
    margin-bottom: 20px;
}

.signInWrapper p {
    font-size: 0.775rem;
}
.signInWrapper p.small,
.signInWrapper p.small button {
    font-size: 0.675rem;
}

.signInWrapper h5.bold,
.signInWrapper p.bold,
.signInWrapper a.bold {
    font-weight: bold;
}

.signInWrapper a {
    text-decoration: underline;
}

.signInWrapper .signInBtn {
    color: #fff;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-size: 0.775rem;
    padding: 9px 50px;
    border-color: #646363;
    border-radius: 0;
}

.signInWrapper .signInBtn:focus,
.signInWrapper .signInBtn:hover{
    background-color: #2b2b2b;
}

.signInHeader {
    padding: 17px 90px 40px 0;
    position: relative;
}

.signInHeaderIcon {
    background-repeat: no-repeat;
    background-position: 100%;
    content: '';
    position: absolute;
    height: 112px;
    width: 112px;
    right: 0;
    top: 0;
}

#username, 
#password,
#usernamereset,
#newpassword,
#code {
    background-color: #fff;
    /* not less than 16px, for not zooming in Safari/iPone */
    font-size: 16px;
    padding: 16px 5px 15px 15px;
    font-family: Lato, sans-serif;
}

.signInInput {
    width: 100%;
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 18px;
}

.signInForm .signInInput:after {
    border-bottom: 3px solid #df32b7;
}

.signInLinks {
    flex-grow: 1;
}

.signInWrapper .BtnAsLink {
    color: #fff;
    padding: 0;
    line-height: 1.46429em;
    text-decoration: underline;
    font-weight: bold;
    border-bottom: 2px solid transparent;
}