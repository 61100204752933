/*General styling*/

/*Widgets*/
.recharts-cartesian-axis-tick {
    font-size: .75rem;
    font-family: Lato, sans-serif;
}

/*Widget layout:*/
.widget-hd {
    padding: 15px 24px;
    font-size: 16px;
    font-family: Lato, sans-serif;
    text-align: left;
    font-weight: 700;
}

.recharts-legend-item {
    margin: 0 0 5px;
    color: #666;
}

.recharts-legend-item-text {
    margin-right: 15px;
    font-family: Lato, sans-serif;
    font-size: .85rem;
}

/*Widget types:*/

.dashboard-widget {
    font-family: Lato, sans-serif;
}

.dashboard-widget .Dashboard-editPenIcon {
    opacity: 0;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.dashboard-widget:hover .Dashboard-editPenIcon {
    opacity: 1;
}

.dashboard-widget.dashboard-widget__type-number {}
.dashboard-widget.dashboard-widget__type-number .widget-body  {
    padding: 15px 24px;
}

.dashboard-widget.dashboard-widget__type-number .widget-body .widget-value {
    font-size: 2rem;
    text-align: left;
    font-weight: 500;
}

.dashboard-widget.dashboard-widget__type-number .widget-body .widget-delta {
    padding: 15px 0;
    font-size: 1.25rem;
    text-align: left;
}

.dashboard-widget.dashboard-widget__type-number .widget-body .widget-delta._positive {
    color: #06A33B;
}

.dashboard-widget.dashboard-widget__type-number .widget-body .widget-delta._negative {
    color: #B02815;
}

/**/

.recharts-default-tooltip {
    font-family: Lato, sans-serif;
}

.tooltip {
    font-family: Lato, sans-serif;
    background: #ffffff;
    padding: 40px;
    box-sizing: border-box;
    border-left: 1px solid #fff;
    z-index: 1000;
    text-align: left;
    margin: 20px;
}
.tooltip .number{
    font-size: 40px;
    display: block;
}
.tooltip .label{
    display: block;
}

.reference-label text{
    fill: rgba(0,0,0, 0.8);
    font-family: 'Lato', sans-serif;
}

.logo-holder {
    width: 100%;
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
}

.logo-holder img {
    max-width: 200px;
    height: auto;
    margin: 0 auto;
    display: block;
}