.user-card {
    padding: 10px 15px;
    margin-bottom: 16px;
}

.user-card__container {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.user-card__part_container {
	display: flex;
	margin-right: 16px;
	width: 25%;
}

.user-card__part_container-tight {
	display: flex;
	margin-right: 16px;
}

.user-card__part_container-last {
	display: flex;
	flex-grow: 1;
}

.user-card__part_container-tight-last {
	display: flex;
}

.user-card__part_item {
	padding: 0px 16px 0 0;
}

.user-card__verified {
	position: relative;
	width: 32px;
}

.user-card__verified > svg {
	position: absolute;
}