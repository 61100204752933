/* Date input */
.DateInput {
    width: 135px;
    background: #1e1e1e;
}

.DateRangePickerInput {
    background: #1e1e1e;
    color: #fff;
    border-radius: 0;
}

.DateInput_input {
    background: #1e1e1e;
    color: #bebebe;
    cursor: pointer;
    font-family: Lato, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    padding: 8px 20px 5px;
}

.DateInput_input::placeholder,
.DateInput_input:-ms-input-placeholder,
.DateInput_input::-webkit-input-placeholder  {
    text-transform: uppercase;
}
.DateRangePickerInput_arrow {
    color: #bebebe;
}

.DateInput_input__focused {
    border-bottom: 2px solid #fffb4e;
    color: #fff;
}

/* General settings */
.DayPicker {
    font-family: Lato, sans-serif;
}

.DateRangePicker_picker,
.CalendarMonthGrid,
.CalendarMonth,
.DayPicker__horizontal {
    background-color: #1f1f1f;
}

.CalendarMonth_caption {
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    padding-top: 25px;
}

.DateInput_fangShape {
    fill: #1e1e1e;
}
.DayPickerKeyboardShortcuts_showSpan, 
.DayPickerKeyboardShortcuts_panel {
    color: #1f1f1f;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #fffb4e;
}
.DayPickerKeyboardShortcuts_closeSvg {
    fill: #1f1f1f;
}

/* Navigation  */
.DayPickerNavigation_button__default {
    border: none;
    background: #343334;
    color: #bdbdbd;
    border-radius: 0;
}


.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
    background: #3f3f3f;
    border: none;
}
.DayPickerNavigation_button__default:focus > .DayPickerNavigation_svg__horizontal,
.DayPickerNavigation_button__default:hover > .DayPickerNavigation_svg__horizontal {
    fill: #fff;
}

.DayPickerNavigation_button__default:active {
    background: #fffb4e;
}

.DayPickerNavigation_button__default:active > .DayPickerNavigation_svg__horizontal {
    fill: #000;
}

/* Weekheader */
.DayPicker_weekHeader_ul {
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 0;
}

/* Calendars days */
.CalendarDay__default {
    border: 1px solid #454444;
    color: #bebebe;
    background: #343434;
}

.CalendarDay__selected {
    background: #fffb4e;
    color: #000;
    border: 1px double #e1de52;
}

.CalendarDay__hovered_span {
   background: #b6b342 !important;
   color: #000;
   border: 1px double #e1de52;
}

.CalendarDay__selected_span {
    background: #b6b342;
    color: #000;
    border: 1px double #e1de52;
}


.CalendarDay__default:hover {
    background: #fffb4e;
    color: #000;
    border: 1px double #fffb4e;
}

.CalendarDay__hovered_span:hover {
    background: #b6b342 !important;
    color: #000;
    border: 1px double #e1de52;
}

.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #313116;
    color: #fff;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #1e1e1e;
    border: 1px solid #2e2d2d;
    color: #636363;
    font-family: Lato, sans-serif;
}

/* Range Picke panel */

.PresetDateRangePicker_panel {
    padding: 0 22px 20px
}
      
  
.PresetDateRangePicker_button {
    height: 100%;
    text-transform: uppercase;
    font-size: 12px;
    border: none;
    background: #353535;
    color: #bebebe;
    transition: all 0.2s ease-out;
}

.PresetDateRangePicker_button:hover {
    background: #3f3f3f;
    color: #fff;
    border: none;
}

.PresetDateRangePicker_button__selected,
.PresetDateRangePicker_button__selected:hover{
    background: #fffb4e;
    color: #000;
}
